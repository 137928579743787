// Importazione dei file SCSS di Bootstrap con utilizzo delle sue Utilities API
@import "~bootstrap/scss/vendor/rfs";
@import "~bootstrap/scss/functions";
@import "bs-colors";
@import "variables";
@import "~bootstrap/scss/variables";
@import "bs-maps";
@import "~bootstrap/scss/maps";
@import "~bootstrap/scss/utilities";
@import "bs-utilities";
@import "~bootstrap/scss/bootstrap";

// Importazione del file per i carousel Slick
@import "~slick-carousel/slick/slick";
@import "~slick-carousel/slick/slick-theme";

// Importazione Fancyapps
@import "@fancyapps/fancybox/dist/jquery.fancybox.min.css";

// Importazione del file per animazione menu hambuger
@import "~hamburgers/_sass/hamburgers/hamburgers";

@import url("https://cdn.jsdelivr.net/npm/bootstrap-icons@1.10.5/font/bootstrap-icons.css");

//#region misc
* {
  outline: none !important;
  -webkit-font-smoothing: antialiased;
  &:focus {
    box-shadow: none !important;
  }
}
body {
  background-color: #000000;
  color: $white;
}
a {
  text-decoration: none;
  color: white;
  
}
a, button {
  transition: 0.3s;
  transition-timing-function: ease-in;
}
a.text-secondary:hover,
a.text-secondary:focus {
  color: $secondary !important;
}
::selection {
  background: $secondary;
  color: $white;
}
*::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}
*::-webkit-scrollbar-track {
  background: $light;
  border: $white;
}
*::-webkit-scrollbar-thumb {
  background: $text-color;
  border-radius: 7px;
}
*::-webkit-scrollbar-thumb:hover {
  background: $primary;
}
//#endregion

.btn-outline {
    border: 1px solid rgb(3,2,20);;
    background: rgb(3,2,20);
    background: linear-gradient(18deg, rgba(3,2,20,1) 0%, rgba(46,46,46,1) 50%, rgba(25,25,25,1) 100%);
    transition: 0.3s;
    transition-timing-function: ease-in;
    z-index: 99;
    height: 74px;
    width: 74px;
    border-radius: 37px;
    @include media-breakpoint-down(lg) {
      height: 60px;
      width: 60px;
      border-radius: 30px;
    }
    button {
      color: #ffffff;
    }
    &:hover {
      background: rgb(23,16,140);
      background: linear-gradient(326deg, rgba(23,16,140,1) 0%, rgba(10,10,163,1) 50%, rgba(86,108,255,1) 100%);
      color: #ffffff;
      border: 1px solid rgb(23,16,140);
      transform: rotate(340deg);
      transition: 0.25s ease-in-out;
      button {
        color: #ffffff;
      }
    }
  }

//#region stickybar
.stickybar {
  background-color: transparent;
  z-index: 9999;
  transition: all 300ms linear;
  color: $white;
  &.stickybar--sticked {
    background-color: $black;
  }
  .nav-link {
    color: $white;
  }
}
//#endregion


//#region PARALLAX
.parallax {
  width: 100%;
  height: 94vh;
  position: relative;
  overflow: hidden;

  &::after {
    content: "";
    width: 100%;
    height: 100%;
    background-color: rgba($color: $black, $alpha: 0.6);
    position: absolute;
    top: 0;
    left: 0;
    
  }

  parallax__img {
    width: 100%;
    object-fit: cover;
    position: relative;
    display: block;
    object-position: center;
  
  }

  &__content {
    position: absolute;
    display: block;
    text-align: center;
    width: 70%;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    color: $white;
    z-index: 1;
    font-size: 66px;
    h1 {
      font-size: 24px;
      text-transform: uppercase;
      font-weight: 400;
      letter-spacing: 0.2rem;
      span {
        font-size: 46px;
      }
    }
    a {
      border: 1px solid rgba(255,255,255,0.60);
    }
  }

  @include media-breakpoint-down(md) {
    height: 90vh;

    &__content {
      width: 100%;
    }
  }


}
//#endregion




//#region Slider Home
.slider__wrapper {
  height: 75vh;
  position: relative;
  @include media-breakpoint-down(md) {
      height: auto;
  }
  .slider {
      position: relative;
      height: 75vh;
      @include media-breakpoint-down(md) {
          height: 70vh;
      }
      overflow: hidden;
      .box__img {
          display: flex;
          width: 100%;
          height: 100vh;
          @include media-breakpoint-down(md) {
              height: 80vh;
          }
          border-radius: 0;
          overflow: hidden;
          .slider__img {
              width: 100%;
              height: 100%;
              object-fit: cover;
              display: block;
              object-position: center;
              @include media-breakpoint-down(md) {
                  height: 50vh;
              }
          }
      }
      .caption-slider {
          position: absolute;
          z-index: 3;
          right: 0;
          height: auto;
          width: auto;
          bottom: 0;
          background-color: $dark;
          padding: 20px;
          padding-bottom: 60px;
          border-top: 2px solid $primary;
          border-left: 2px solid $primary;
          -webkit-box-shadow: -10px -4px 24px 0px rgba(0, 0, 0, 0.22);
          box-shadow: -10px -4x 24px 0px rgba(0, 0, 0, 0.22);
          @include media-breakpoint-down(md) {
              position: relative;
              height: 30vh;
              width: 100%;
              left: 0;
              padding-bottom: 20px;
              border-top: 1px solid $white;
              border-left: none;
              -webkit-box-shadow: none;
              box-shadow: none;
          }
          h2 {
              text-transform: uppercase;
              font-weight: normal;
              font-size: large;
              color: $white;
              @include media-breakpoint-down(lg) {
                  font-size: x-large;
                  letter-spacing: normal;
                  font-weight: 500;
              }
          }
      }
  }
  .slider__arrow {
      position: absolute;
      z-index: 2;
      top: 50%;
      transform: translate(0, -50%);
      @include media-breakpoint-down(md) {
          top: auto;
          bottom: 34px;
          transform: none;
          img {
              @include media-breakpoint-down(md) {
                  max-width: 14px;
              }
          }
      }
      &.slider__arrow--next {
          right: 30px;
          @include media-breakpoint-down(md) {
              right: 42%;
          }
      }
      &.slider__arrow--prev {
          left: 30px;
          @include media-breakpoint-down(md) {
              left: 42%;
          }
      }
  }
}
footer {
  p {
    font-family: $font-family-sans-serif;
    font-size: 12px;
  }
}

//#region stickybar

//#region navbar
.navbar {
  z-index: 999;
}
.navbar-collapse {
  width: 100%;
  background: black;
  position: fixed;
  z-index: 9;
  display: block;
  visibility: hidden;
  opacity: 0;
  z-index: -1;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  transition: all 0.4s;

  .social-nav {
    font-size: 19px;
    font-family: $font-family-sans-serif;
  }

  .title-nav {
    font-size: 24px;
    text-transform: uppercase;
    .text-special {
      text-transform: none;
      font-style: italic;
      @include media-breakpoint-down(sm) {
        font-size: 19px;
        line-height: normal;
      }
    }
  }

  ul.navbar-nav {
    position: absolute;
    top: 150px;
    @include media-breakpoint-down(md) {
      top: 90px;
    }
    @include media-breakpoint-down(sm) {
      top: 60px;
    }
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;

    li {
      opacity: 0;
      transition: opacity 0.4s cubic-bezier(0.58, 0.3, 0.005, 1);
      padding: 0 15px;
      margin-bottom: 20px;
      a {
        color: white;
        font-size: 40px;
        padding: 0;
      }
    }
  }

  &.show {
    min-height: 100vh;
    visibility: visible;
    opacity: 1;
    z-index: 9;
    ul {
      li, div {
        opacity: 1;
        &:nth-child(1) {
          transition-delay: 0.1s;
        }
        &:nth-child(2) {
          transition-delay: 0.2s;
        }
        &:nth-child(3) {
          transition-delay: 0.3s;
        }
        &:nth-child(4) {
          transition-delay: 0.4s;
        }
        &:nth-child(5) {
          transition-delay: 0.45s;
        }
        &:nth-child(6) {
          transition-delay: 0.50s;
        }
      }
    }
  }
}

.navbar-toggler {
  padding: 0;
  z-index: 9999;
  #nav-icon {
    width: 30px;
    height: 22px;
    position: relative;
    margin: 0 auto;
    transform: rotate(0deg);
    cursor: pointer;
    span {
      display: block;
      position: absolute;
      height: 5px;
      width: 50%;
      background: white;
      opacity: 1;
      transform: rotate(0deg);
      transition: 0.25s ease-in-out;
    }
  }

  &[aria-expanded="false"] {
    #nav-icon {
      span:nth-child(even) {
        left: 50%;
      }
      span:nth-child(odd) {
        left: 0px;
      }
      span:nth-child(1),
      span:nth-child(2) {
        top: 0px;
      }
      span:nth-child(3),
      span:nth-child(4) {
        top: 9px;
      }
      span:nth-child(5),
      span:nth-child(6) {
        top: 18px;
      }
    }
  }

  &[aria-expanded="true"] {
    #nav-icon {
      span:nth-child(1),
      span:nth-child(6) {
        transform: rotate(45deg);
      }
      span:nth-child(2),
      span:nth-child(5) {
        transform: rotate(-45deg);
      }
      span:nth-child(1) {
        left: 5px;
        top: 7px;
      }
      span:nth-child(2) {
        left: calc(50% - 5px);
        top: 7px;
      }
      span:nth-child(3) {
        left: -50%;
        opacity: 0;
      }
      span:nth-child(4) {
        left: 100%;
        opacity: 0;
      }
      span:nth-child(5) {
        left: 5px;
        top: 12px;
      }
      span:nth-child(6) {
        left: calc(50% - 5px);
        top: 12px;
      }
    }
  }
}
//#endregion navbar

//#region Modal Dialog EVENTS
.modal {
  z-index: 99999999;
  .modal-dialog {
    .modal-content {
      //border: 1px solid #454545;
      box-shadow: 0 16px 28px 0 rgba(0,0,0,0.5), 0 25px 55px 0 rgba(0,0,0,0.46);
      .btn-close-evo {
        border-radius: 50%; 
        z-index: 999999;
        color: $white;
        height: 40px;
        width: 40px;
        background: transparent;
        border: none;
        outline: none;
        box-shadow: none;
        padding:0;
        top: 0;
        right: 0;
        margin-top: 6px;
        margin-right: 6px;
        .bi {
          font-size: 3rem;
          margin-top: 4px;
          &::before {
            text-shadow: 2px 2px 4px rgba(0,0,0,0.5);
          }
        }
      }
    }
    
  }
}


@-webkit-keyframes custome-animate {
	0% {
		-webkit-transform-style: preserve-3d;
		-moz-transform-style: preserve-3d;
		transform-style: preserve-3d;
		-webkit-transform:  perspective(90%) rotateY(-65deg) rotateX(-45deg) translateZ(-200px);
		-moz-transform: perspective(90%) rotateY(-65deg) rotateX(-45deg) translateZ(-200px);
		-ms-transform: perspective(90%) rotateY(-65deg) rotateX(-45deg) translateZ(-200px);
		transform: perspective(90%) rotateY(-65deg) rotateX(-45deg) translateZ(-200px);
		opacity: 0;
	}
    68% {
		-webkit-transform:  rotateY(10deg) rotateX(10deg) translateZ(20px);
		-moz-transform:  rotateY(10deg) rotateX(10deg) translateZ(20px);
		-ms-transform:  rotateY(10deg) rotateX(10deg) translateZ(20px);
		transform:  rotateY(10deg) rotateX(10deg) translateZ(20px);
		opacity: 0.8;                
    }
	100% {
		-webkit-transform:  rotateY(0deg) rotateX(0deg) translateZ(0px);
		-moz-transform:  rotateY(0deg) rotateX(0deg) translateZ(0px);
		-ms-transform:  rotateY(0deg) rotateX(0deg) translateZ(0px);
		transform:  rotateY(0deg) rotateX(0deg) translateZ(0px);
		opacity: 1;
	}
}
@keyframes custome-animate {
	0% {
		-webkit-transform-style: preserve-3d;
		-moz-transform-style: preserve-3d;
		transform-style: preserve-3d;
		-webkit-transform: perspective(90%) rotateY(-65deg) rotateX(-45deg) translateZ(-200px);
		-moz-transform: perspective(90%) rotateY(-65deg) rotateX(-45deg) translateZ(-200px);
		-ms-transform: perspective(90%) rotateY(-65deg) rotateX(-45deg) translateZ(-200px);
		transform: perspective(90%) rotateY(-65deg) rotateX(-45deg) translateZ(-200px);
		opacity: 0;
	}
	68% {
		-webkit-transform:  rotateY(10deg) rotateX(10deg) translateZ(20px);
		-moz-transform:  rotateY(10deg) rotateX(10deg) translateZ(20px);
		-ms-transform:  rotateY(10deg) rotateX(10deg) translateZ(20px);
		transform:  rotateY(10deg) rotateX(10deg) translateZ(20px);
		opacity: 0.8;                
    }
    100% {
		-webkit-transform: rotateY(0deg) rotateX(0deg) translateZ(0px);
		-moz-transform:  rotateY(0deg) rotateX(0deg) translateZ(0px);
		-ms-transform:  rotateY(0deg) rotateX(0deg) translateZ(0px);
		transform:  rotateY(0deg) rotateX(0deg) translateZ(0px);
		opacity: 1;
	}
}
.custome-animate {
    -webkit-animation-duration: 0.75s;
    animation-duration: 0.75s;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
	-webkit-animation-name: custome-animate;
	animation-name: custome-animate;
}