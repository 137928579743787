//#region custom fonts
/* @font-face {
    font-family: "My-Font";
    src: url("./../fonts/My-Font.woff") format("woff"),
      url("./../fonts/My-Font.eot"),
      url("./../fonts/My-Font.eot?#iefix") format("embedded-opentype");
    font-weight: normal;
    font-style: normal;
} */
//#endregion
//#region limits (max-width)
$i: 0;
@while $i <= 2000 {
  $i: $i + 50;
  .is-limited-#{$i} {
    max-width: #{$i}px;
  }
}
//#endregion

//#region bootstrap variables
$container-max-widths: (
  xl: 1400px,
);

$enable-transitions: false;
$enable-shadows: false;
$enable-rounded: false;
$enable-caret: false;
$breadcrumb-divider: quote(">");

$spacer: 1rem;
$spacers: (
  0: 0,
  1: $spacer * 0.25,
  2: $spacer * 0.5,
  3: $spacer,
  4: $spacer * 1.5,
  5: $spacer * 3,
);
$grid-gutter-width: 1.5rem;
$gutters: (
  0: 0,
  1: $spacer * 0.25,
  2: $spacer * 0.5,
  3: $spacer,
  4: $spacer * 1.5,
  5: $spacer * 3,
);

$font-family-base: 'Crimson Text', serif;
$font-family-serif: 'Crimson Text', serif;
$font-family-sanserif: 'Source Sans Pro', sans-serif;
$text-color: #ffffff;
$body-bg: #000000;
$body-color: $text-color;
$link-color: $text-color;
$border-color: #eeeeee;

$primary: #00bddf;
$secondary: #00b772;
$success: #00b772;
$info: #d2d2d2;
$warning: #e56700;
$danger: #e50019;
$light: #f5f5f5;
$dark: #797979;
//#endregion

//#region slick variables
$slick-font-path: "~slick-carousel/slick/fonts/";
$slick-loader-path: "~slick-carousel/slick/";
//#endregions

//#region hamburgers variables
$hamburger-padding-x: 0px;
$hamburger-padding-y: 15px;
$hamburger-layer-width: 40px;
$hamburger-layer-height: 2px;
$hamburger-layer-spacing: 10px;
$hamburger-layer-color: white;
$hamburger-layer-border-radius: 0px;
$hamburger-hover-opacity: 1;
$hamburger-active-layer-color: $hamburger-layer-color;
$hamburger-active-hover-opacity: $hamburger-hover-opacity;
//#endregion
